<template>
     <main class="ml-12">
        <div class="wrapper">
            <div class="flex flex-row justify-between items-end">
                <ul class="flex-col mt-12 mb-8">
                    <li><a href="#">Inventory Control</a></li>
                    <li class="font-bold mt-4 text-2xl">
                        Transfer Order 1
                    </li>
                </ul>
                <div class="mb-8 flex flex-row gap-5">
                    <trac-button class="uppercase">Cancel Order</trac-button>
                    <trac-button class="uppercase">Hold Order</trac-button>
                    <trac-button class="uppercase">Close</trac-button>
                </div>
            </div>
        </div>
         <div class=" bg-white px-8 py-8 rounded-lg  h-full  overflow-hidden big-shadow">
            <div class="flex flex-row gap-8">
                <ul class="w-4/12">
                    <li class="text-sm font-medium text-primaryGray">Source store:</li>
                    <li class="text-base font-medium">Main</li>
                </ul>
                <ul class="w-4/12">
                    <li class="text-sm font-medium text-primaryGray">Destination store:</li>
                    <li class="text-base font-medium">Store 2</li>
                </ul>
                <ul class="w-4/12">
                    <li class="text-sm font-medium text-primaryGray">Ordered by:</li>
                    <li class="text-base font-medium">Main</li>
                </ul>
            </div>
            <div class="flex flex-row gap-8 mt-10">
                <ul class="w-4/12">
                    <li class="text-sm font-medium text-primaryGray">Transfer status:</li>
                    <li class="text-base font-medium">Held</li>
                </ul>
                <ul class="w-4/12">
                    <li class="text-sm font-medium text-primaryGray">Transfer date:</li>
                    <li class="text-base font-medium">2/10/2020</li>
                </ul>
                <ul class="w-4/12">
                    <li class="text-sm font-medium text-primaryGray">Received date:</li>
                    <li class="text-base font-medium">2/22/2020</li>
                </ul>
            </div>
        </div>
        <div class=" bg-white px-8 py-8 rounded-lg mt-5 h-full overflow-hidden big-shadow">
            <div class=" border rounded-lg h-full overflow-auto ">
                <table class="w-full table-auto h-full overflow-auto">
                    <thead class="bg-blue-100">
                    <tr class="">
                        <th class="text-left text-xs font-semibold p-4 uppercase">Item</th>
                        <th class="text-left text-xs font-semibold p-4 uppercase">SKU</th>
                        <th class="text-left text-xs font-semibold p-4 uppercase">Category</th>
                        <th class="text-left text-xs font-semibold p-4 uppercase">Units Received</th>
                        <th class="text-left text-xs font-semibold p-4 uppercase">Price</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                            :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                            v-for="(item, index) in items"
                            class="hover:bg-gray-200 cursor-pointer"
                            :key="index"
                    >
                        <td class="text-sm font-bold p-4 capitalize">
                            {{item.name}}
                        </td>
                        <td class="text-xs font-medium p-4">
                            {{ item.sku }}
                        </td>
                        <td class="text-xs font-medium p-4 capitalize">
                            {{ item.category }}
                        </td>
                        <td class="text-xs font-medium p-4 capitalize">
                            {{ item.units_received }}
                        </td>
                        <td class="text-xs font-medium p-4 capitalize">
                            ₦ {{ item.price }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
     </main>
</template>

<script>
    export default {
        name: "TransferOrder",
        data(){
            return{
                items:[
                    {name:'Amstel Malta', sku:'10686699441146', category:'Test', units_received:100, price:'200.00'},
                ]
            }
        }
    }
</script>

<style scoped>

</style>